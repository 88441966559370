import API from '@/config/api-routers';
import { makeGetRequest } from './requests';
import { DaDataSearchItem } from '@/interfaces/bankResponse.interface';

/**
 * Get admin menu.
 */
export const getSuggestionBanksByBic = async (query: string): Promise<DaDataSearchItem[]> => {
  const url = API.geo.bank.replace('$1', query);
  const result = await makeGetRequest(url);

  return result.data.result;
};

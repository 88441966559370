import { Component, Vue } from '@/lib/decorator';
import { EventBus } from '@/lib/event-bus';

type CallbackFunction = (data?: any) => void;

@Component({})
export default class Form extends Vue {
  enterDisabled = false;
  isBlockForm = false;

  returnBefore() {
    console.info('returnBefore');
  }

  async blockQuery(callback: CallbackFunction) {
    if (this.isBlockForm) {
      return;
    }

    this.isBlockForm = true;
    await callback();
    this.isBlockForm = false;
  }

  save() {
    console.info('save');
  }

  created() {
    if (!this.enterDisabled) {
      EventBus.$on('pressEnter', this.save);
    }
    EventBus.$on('pressEsc', this.returnBefore);
  }

  unmounted() {
    if (!this.enterDisabled) {
      EventBus.$off('pressEnter', this.save);
    }
    EventBus.$off('pressEsc', this.returnBefore);
  }
}
